import { useQuery } from '@tanstack/react-query';
import ClientTagService from '../services/ClientTagService';
import { ClientTagType } from '../models/ClientTag';
import { useCurrentClient } from '../global-state/Clients';
import { useMemo } from 'react';
import { defaultMemberFields, MemberFieldKey } from '../models/ClientMemberFields';
import User from '../models/User';
import useFetchClientUsers from './useFetchClientUsers';
import { Translations } from '../models/Translation';
import { Client } from '../models/Client';

export type ClientUserGrouping = {
  id: string;
  translations: Translations<'name'>;
  type: ClientTagType;
  users: (User & { tagIds?: string[] })[];
  formSectionId: string | null;
};

export const useGetActiveClientGroups = (currentClient: Client) => {
  const tagTypes = useMemo(() => {
    const memberFields = (currentClient?.memberFields || defaultMemberFields.filter((x) => x.isEditable && x.isRequired)).map((x) => x.key);

    return [
      memberFields.includes(MemberFieldKey.Department) ? ClientTagType.UserDepartment : null,
      memberFields.includes(MemberFieldKey.Group) ? ClientTagType.UserGroup : null,
      memberFields.includes(MemberFieldKey.Position) ? ClientTagType.UserPosition : null,
    ].filter((x) => x !== null) as ClientTagType[];
  }, [currentClient?.memberFields]);

  return tagTypes;
};

export const useFetchActiveClientGroups = () => {
  const currentClient = useCurrentClient((x) => x.value);
  const clientUsers = useFetchClientUsers();

  const tagTypes = useGetActiveClientGroups(currentClient!);

  const { data, refetch } = useQuery({
    queryKey: ['clientGroups', currentClient?.id, tagTypes],
    staleTime: 5 * 60 * 1000, // 5 mins
    queryFn: () => {
      return ClientTagService.getAllTags(currentClient!.id, {
        types: tagTypes,
        includeItemIds: true,
      });
    },
  });

  const clientGroups = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.data.map((tag) => {
      const users = tag.itemIds.map((id) => clientUsers.data?.find((x) => x.id === id)).filter((x): x is User => x !== undefined);

      return {
        id: tag.id,
        translations: tag.translations,
        type: tag.type,
        users,
        formSectionId: tag.formSectionId,
      };
    });
  }, [clientUsers.data, data]);

  return { data: clientGroups, refetch };
};
